import Image from 'next/image';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import insectImage from '@/images/non-hyperlocal/insect.webp';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import Paragraph, { VARIANT } from './Paragraph';
const ProtectCropBanner = () => {
  const {
    t
  } = useTranslation('main');
  return <div className="p-4" data-sentry-component="ProtectCropBanner" data-sentry-source-file="ProtectCropBanner.tsx">
      <Link className="block bg-yellow-180 border border-black overflow-hidden p-4 relative rounded-md shadow-[0_6px_20px_rgba(0,0,0,0.3)] w-full" href="/crops" onClick={() => trackCustomEvent(CustomEventTypes.HOME_BANNER, 'Crop Diseases')} data-testid="protect-banner-link" data-sentry-element="Link" data-sentry-source-file="ProtectCropBanner.tsx">
        <span className="font-normal text-yellow-70 text-2xl w-56">
          {t('protect_crop_insect')}
        </span>
        <br />
        <span className="font-bold text-yellow-70 text-2xl w-56">
          {t('protect_crop_20_loss')}
        </span>
        <Paragraph className="mt-2 relative text-white w-fit z-10" variant={VARIANT.SMALL} data-sentry-element="Paragraph" data-sentry-source-file="ProtectCropBanner.tsx">
          {t('protect_crop_do_not_ignore')}
        </Paragraph>
        <Paragraph className="bg-white font-bold mt-4 p-2 rounded-md text-primary-100 w-fit" variant={VARIANT.MEDIUM} data-sentry-element="Paragraph" data-sentry-source-file="ProtectCropBanner.tsx">
          {t('protect_crop_banner_cta')}
        </Paragraph>
        <div className="absolute -bottom-0.5 h-32 -right-1 sm:right-12 w-32">
          <Image alt={t('protect_crop_banner_title')} src={insectImage} data-sentry-element="Image" data-sentry-source-file="ProtectCropBanner.tsx" />
        </div>
        <div className="absolute bg-secondary-two-100 -right-14 rotate-45 text-center text-white text-xs top-4 w-40">
          {t('protect_crop_hurry')}
        </div>
      </Link>
    </div>;
};
export default ProtectCropBanner;