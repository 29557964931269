import { post } from '@/utils/axios';
const likeDislkeCommunityPost = async (postId: number, postType?: string) => {
  const url = postType === 'community' ? `/community/v1/posts/${postId}/like` : `/blog/v1/post/${postId}/like`;
  try {
    const result = await post(url, {});
    if (result) return true;
  } catch (e) {
    console.error(e);
  }
  return false;
};
export default likeDislkeCommunityPost;