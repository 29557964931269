import { COMMON_STYLES, SimplePostCard } from '@dehaat/dds';
import followUnfollowChannel from '@dehaat/kisan-app-bl/api/followUnfollowPostEntity';
import { HeadData, Tags } from '@dehaat/kisan-app-bl/models/PostsV2';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useContext, useEffect, useState } from 'react';
import BottomDrawer from '@/components/BottomDrawer';
import ErrorModal from '@/components/ErrorModal';
import LoginDrawer from '@/components/LoginDrawer';
import Okay from '@/components/modalActions/Okay';
import { POST_TYPE } from '@/constants/postv2';
import { AuthContext } from '@/context/AuthProvider';
import useErrorModalState from '@/hooks/useErrorModalState';
import { Attachments } from '@/models/Posts';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import likeDislikePost from '@/utils/api/likeDislikePost';
import { isArray } from '@/utils/helper';
import { relativeTime } from '@/utils/helpers/getDate';
import getWhatsappShareURL from '@/utils/helpers/getWhatsappShareURL';
import Paragraph, { VARIANT as PARAGRAPH_VARIANT } from '../../Paragraph';
import MediaCardV2 from '../MediaCardV2';
import UserVerifiedImage from '../UserVerifiedImage';
export interface SimplePostListProps {
  data: Props[];
  callbackFn: VoidFunction;
}
interface Props {
  id: number;
  is_liked: boolean;
  is_followed: boolean;
  like_count: number;
  comment_count: number;
  created_at: string;
  head: HeadData | null;
  attachments: Attachments;
  tags: Tags[] | null;
  name: string;
  post_type: string;
  slug: string;
  callback?: VoidFunction;
}
export const SimplePostCardItems = ({
  id,
  is_liked,
  like_count,
  comment_count,
  created_at,
  head,
  attachments,
  tags,
  name,
  post_type,
  slug,
  callback
}: Props) => {
  const {
    t
  } = useTranslation('cropProblems');
  const [likeCount, setLikeCount] = useState(like_count || 0);
  const [commentCount] = useState(comment_count);
  const [isLiked, setIsLiked] = useState(is_liked || false);
  const [isFollowed, setIsFollowed] = useState(head?.is_followed);
  const [href, setHref] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const {
    errorModalState,
    hideErrorModal,
    setErrorModalState
  } = useErrorModalState();
  const {
    push,
    asPath
  } = useRouter();
  const toggleLikeBtn = async () => {
    if (!isAuthenticated) {
      toggleDrawer();
      return;
    }
    const result = await likeDislikePost(id, 'dehaat');
    if (result) {
      if (isLiked) {
        setLikeCount(prev => prev - 1);
      } else {
        setLikeCount(prev => prev + 1);
      }
      setIsLiked(prev => !prev);
    } else {
      setErrorModalState({
        ...errorModalState,
        show: true,
        allowClose: true
      });
    }
  };
  const toggleFollowBtn = async () => {
    if (!isAuthenticated) {
      toggleDrawer();
      return;
    }
    const result = await followUnfollowChannel(head!.follow_entity.id, 'CHANNEL', isFollowed ? 'unfollow' : 'follow');
    if (result) {
      if (callback) callback();
      setIsFollowed(prev => !prev);
    } else {
      setErrorModalState({
        ...errorModalState,
        show: true,
        allowClose: true
      });
    }
  };
  const handleComment = () => {
    push(`/blog/${slug}/?c=true`);
  };
  const handleCardClick = () => {
    push(`/blog/${slug}`);
  };
  const handleShare = (postType = '') => {
    const eventType = postType == POST_TYPE.Community ? CustomEventTypes.COMMUNITY_POST_SHARED : CustomEventTypes.DEHAAT_FEED_SHARED;
    trackCustomEvent(eventType, isArray(tags) ? JSON.stringify(tags) : undefined);
    const hRef = href ? getWhatsappShareURL(href, slug, 'BLOG') : undefined;
    window.open(hRef);
  };
  const handleChannelClick = () => {
    if (asPath.includes('/blogs/channel')) {
      return;
    }
    const {
      slug
    } = head!.slug;
    push(`/blogs/channel/${slug}`);
  };
  const handleTagClick = (tag: Tags) => {
    const {
      slug
    } = tag.slug;
    push(`/blogs/tag/${slug}`);
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(prev => !prev);
  };
  useEffect(() => {
    setHref(`${location.origin}/blog/${slug}`);
  }, [slug]);
  return <>
      <SimplePostCard containerStyle={[COMMON_STYLES.fullWidth, {
      marginTop: 16
    }]} createdAt={relativeTime(created_at)} isFollowed={isFollowed} name={head?.name || ''} label={head?.label || ''} labelTextColor={head?.color?.text} likeCount={likeCount} onChannelClick={handleChannelClick} commentCount={commentCount} isLiked={isLiked} isVerified={head?.is_verified || false} onFollowUnfollowBtnClick={toggleFollowBtn} imageUrl={head?.display_photo || undefined} tags={tags} fallBackUserImageOrIcon={<div className="w-10 h-10 mr-1 rounded-lg">
            <UserVerifiedImage alt={head?.name || ''} imageUrl={head?.display_photo} fallbackImageClassName="rounded-lg" />
          </div>} onCardClick={handleCardClick} onShareClick={() => handleShare(post_type)} onLikeClick={toggleLikeBtn} onChipClick={handleTagClick} onCommentClick={handleComment} onThreeDotClick={() => undefined} data-sentry-element="SimplePostCard" data-sentry-source-file="SimplePostList.tsx">
        <div className="my-2 flex flex-col cursor-pointer md:h-auto" data-testid="media-card">
          <Paragraph variant={PARAGRAPH_VARIANT.BIG} className="text-neutral-80" data-sentry-element="Paragraph" data-sentry-source-file="SimplePostList.tsx">
            {name}
            <Link href={`/blog/${slug}`} className="text-primary-100 ml-2 font-medium" data-sentry-element="Link" data-sentry-source-file="SimplePostList.tsx">
              {`${t('read_more')}...`}
            </Link>
          </Paragraph>
          <div className="flex justify-center">
            <MediaCardV2 attachments={attachments} slug={slug} data-sentry-element="MediaCardV2" data-sentry-source-file="SimplePostList.tsx" />
          </div>
        </div>
      </SimplePostCard>
      <BottomDrawer show={isDrawerOpen} onClose={toggleDrawer} data-sentry-element="BottomDrawer" data-sentry-source-file="SimplePostList.tsx">
        <LoginDrawer data-sentry-element="LoginDrawer" data-sentry-source-file="SimplePostList.tsx" />
      </BottomDrawer>
      <ErrorModal {...errorModalState} onClose={hideErrorModal} data-sentry-element="ErrorModal" data-sentry-source-file="SimplePostList.tsx">
        <Okay handleClick={hideErrorModal} data-sentry-element="Okay" data-sentry-source-file="SimplePostList.tsx" />
      </ErrorModal>
    </>;
};
const SimplePostList = ({
  data,
  callbackFn
}: SimplePostListProps) => {
  return <div className="rounded-xl flex flex-col md:items-center mx-3" data-sentry-component="SimplePostList" data-sentry-source-file="SimplePostList.tsx">
      {data?.map(items => {
      const {
        post_type
      } = items;
      switch ((post_type as POST_TYPE)) {
        case POST_TYPE.Blog:
          return <SimplePostCardItems key={items.id} {...items} is_followed={items.head?.is_followed || false} callback={callbackFn} />;
        default:
          return null;
      }
    })}
    </div>;
};
export default SimplePostList;