import { PropsWithChildren, TouchEventHandler, useEffect, useRef, useState } from 'react';
interface Props {
  pages: number;
  carouselClassName: string;
  handlerClassName: string;
  pageUpdateCallback?: (pageIndex: number) => void;
  autoplay?: boolean;
  dotsClassName?: string;
  activeDotClassName?: string;
}
const CarouselWithHandlers = ({
  pages,
  carouselClassName,
  handlerClassName,
  pageUpdateCallback,
  children,
  autoplay = false,
  dotsClassName,
  activeDotClassName
}: PropsWithChildren<Props>) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [touchStarted, setTouchStarted] = useState<number | null>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const onTouchStart: TouchEventHandler = event => {
    setTouchStarted(event.changedTouches[0].pageX);
  };
  const updateCurrentPage = (index: number) => {
    setCurrentPage(index);
    pageUpdateCallback?.(index);
  };
  const onTouchMove: TouchEventHandler = event => {
    if (touchStarted != null && carouselRef.current) {
      const currentPosition = event.changedTouches[0].pageX;
      const delta = touchStarted - currentPosition;
      if (delta < 0 && currentPage > 0) {
        carouselRef.current.style.transform = `translateX(calc(-${100 / pages * currentPage}% + ${delta * -1}px))`;
      } else if (delta > 0 && currentPage < pages - 1) {
        carouselRef.current.style.transform = `translateX(calc(-${100 / pages * currentPage}% - ${delta}px)`;
      }
    }
  };
  const onTouchEnd: TouchEventHandler = event => {
    if (touchStarted != null && carouselRef.current) {
      const currentPosition = event.changedTouches[0].pageX;
      const delta = touchStarted - currentPosition;
      if (delta > 50) {
        updateCurrentPage(currentPage < pages - 1 ? currentPage + 1 : currentPage);
      } else if (delta < -50) {
        updateCurrentPage(currentPage > 0 ? currentPage - 1 : currentPage);
      } else {
        carouselRef.current.style.transform = `translateX(-${100 / pages * currentPage}%)`;
      }
    }
    setTouchStarted(null);
  };
  useEffect(() => {
    let timer: number;
    if (autoplay) {
      timer = window.setInterval(() => {
        setCurrentPage(prev => prev === pages - 1 ? 0 : prev + 1);
      }, 5000);
    }
    return () => clearInterval(timer);
  }, [autoplay, pages]);
  return <>
      <section className={`flex transition-transform duration-300 ease-linear ${carouselClassName}`} style={{
      width: `${pages * 100}%`,
      transform: `translateX(-${100 / pages * currentPage}%)`
    }} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} ref={carouselRef} data-testid="carousel-container">
        {children}
      </section>
      {pages > 1 ? <footer className={`flex items-center justify-center ${handlerClassName}`}>
          {Array(pages).fill(null).map((_, index) => <button className={`rounded-full ${currentPage === index ? `p-1 bg-primary-100 ${activeDotClassName}` : 'p-[3px] bg-neutral-40'} transition-all duration-300 ${dotsClassName}`} onClick={() => updateCurrentPage(index)} aria-label={`click to view card number ${index}`} data-testid={`carousel_button_${index}`} key={`carousel_${index}`} />)}
        </footer> : null}
    </>;
};
export default CarouselWithHandlers;