import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useRef, useState } from 'react';
import MessageQuestionIcon from '@/components/icons/MessageQuestionIcon';
import Paragraph, { VARIANT } from '@/components/Paragraph';
import useThrottle from '@/hooks/useThrottle';
const FloatingHelp = () => {
  const lastScrollTop = useRef(typeof window !== 'undefined' ? document.documentElement.scrollTop : 0);
  const [isOpen, setIsOpen] = useState(true);
  const {
    t
  } = useTranslation('askQuestion');
  const scrollHandler = useCallback(() => {
    // Process only vertical scroll events
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop !== lastScrollTop.current) {
      setIsOpen(scrollTop < lastScrollTop.current);
      lastScrollTop.current = scrollTop;
    }
  }, []);
  const throttledScrollHandler = useThrottle(scrollHandler, 100);
  useEffect(() => {
    window.addEventListener('scroll', throttledScrollHandler);
    return () => window.removeEventListener('scroll', throttledScrollHandler);
  }, [throttledScrollHandler]);
  return <Link className={`bg-primary-100 bottom-20 duration-300 ease-in-out fixed flex items-center overflow-hidden p-4 right-6 shadow-[0_12px_16px_rgba(0,0,0,0.5)] text-white transition-all z-10 ${isOpen ? 'rounded-xl w-36' : 'rounded-[28px] w-14'}`} href="/ask-help" data-sentry-element="Link" data-sentry-component="FloatingHelp" data-sentry-source-file="FloatingHelp.tsx">
      <MessageQuestionIcon className="fill-white grow-0 h-6 shrink-0 w-6" data-sentry-element="MessageQuestionIcon" data-sentry-source-file="FloatingHelp.tsx" />
      <Paragraph className="grow ml-2 text-center truncate" isHighlighted variant={VARIANT.BIG} data-sentry-element="Paragraph" data-sentry-source-file="FloatingHelp.tsx">
        {t('ask_help')}
      </Paragraph>
    </Link>;
};
export default FloatingHelp;