import { LAT_LNG_COOKIE_NAME, NEARBY_DC_RANGE } from '@/constants/common'
import NearByDC from '@/models/NearByDc'

import { get } from '../axios'
import { getCookieValue } from '../helper'

const fetchNearByDcs = async (
  latLong = getCookieValue(LAT_LNG_COOKIE_NAME)
) => {
  if (latLong) {
    try {
      const params = {
        lat_lng: latLong,
        distance: NEARBY_DC_RANGE,
      }
      return await get<NearByDC[]>('miniapps/v1/centerlocator', params)
    } catch {
      return []
    }
  }
  return []
}

export default fetchNearByDcs
