import fetchProductsV2 from '@dehaat/kisan-app-bl/api/fetchProductsV2'
import { CanceledError } from 'axios'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

import { PRODUCTS_QUERY_LIMIT, VENDOR_ID_COOKIE_NAME } from '@/constants/common'
import { Product, ProductsQueryType } from '@/models/Product'
import { formatAllProducts } from '@/utils/cart'
import { fixNextPageUrl, getCookieValue } from '@/utils/helper'

import useInfiniteScroll from './useInfiniteScroll'

const useSearchProductsV2 = (limit = PRODUCTS_QUERY_LIMIT) => {
  const { query } = useRouter()
  const { s: encodedSearchQuery }: ProductsQueryType = query
  const [nextPageUrl, setNextPageUrl] = useState<string | null>(null)
  const [searchedProducts, setSearchedProducts] = useState<Product[]>([])
  const [searchingProducts, setSearchingProducts] = useState<boolean>(false)

  const onSearchParamsChanged = useCallback(
    async (encodedSearchQuery: string) => {
      const vendorId = getCookieValue(VENDOR_ID_COOKIE_NAME)
      setSearchingProducts(true)
      try {
        const response = await fetchProductsV2(null, {
          page: 1,
          page_size: limit,
          vendor_id: vendorId,
          query: encodedSearchQuery,
        })

        if (response?.results) {
          const formatedProducts = formatAllProducts(response.results)
          setSearchedProducts((prevProducts) =>
            prevProducts.concat(...formatedProducts)
          )
          setNextPageUrl(fixNextPageUrl(response.next) || null)
          setSearchingProducts(false)
        }
      } catch (e) {
        if (!(e instanceof CanceledError)) {
          setSearchingProducts(false)
        }
      }
    },
    []
  )

  const { infiniteScrollRef } = useInfiniteScroll(
    useCallback(async () => {
      if (nextPageUrl) {
        setSearchingProducts(true)
        try {
          const response = await fetchProductsV2(nextPageUrl)
          if (response?.results) {
            const formatedProducts = formatAllProducts(response.results)
            setSearchedProducts((prevProducts) =>
              prevProducts.concat(...formatedProducts)
            )
            setNextPageUrl(fixNextPageUrl(response.next) || null)
            setSearchingProducts(false)
          }
        } catch (e) {
          if (!(e instanceof CanceledError)) {
            setSearchingProducts(false)
          }
        }
      }
    }, [nextPageUrl])
  )

  useEffect(() => {
    setSearchedProducts([])
    if (encodedSearchQuery) {
      onSearchParamsChanged(encodedSearchQuery as string)
    }
  }, [encodedSearchQuery, onSearchParamsChanged])

  return {
    infiniteScrollRef,
    searchedProducts,
    searchingProducts,
    searchQuery: encodedSearchQuery
      ? decodeURIComponent(encodedSearchQuery)
      : '',
  }
}

export default useSearchProductsV2
