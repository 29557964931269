import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useState } from 'react';
import NearByDC from '@/models/NearByDc';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import fetchNearByDcs from '@/utils/api/fetchNearByDCs';
import CarouselWithHandlers from './CarouselWithHandlers';
import DCCard from './DCCard';
import SubHeading, { VARIANT } from './SubHeading';
interface Props {
  className?: string;
  createGaEvents?: boolean;
  cropId?: number;
  cropProblemId?: number;
}
const NearByDCList = ({
  className = '',
  createGaEvents = false,
  cropId,
  cropProblemId
}: Props) => {
  const {
    t
  } = useTranslation('nearbyDc');
  const [deHaatCenters, setDeHaatCenters] = useState<NearByDC[]>([]);
  const scrollDcsEvent = (index: number) => {
    if (createGaEvents) {
      trackCustomEvent(CustomEventTypes.VENDOR_CARD_RIGHT_SCROLL, JSON.stringify({
        cropId,
        cropProblemId,
        dehaatCenter: deHaatCenters[index].address
      }));
    }
  };
  const getNearByDcs = useCallback(async () => {
    const dcs = await fetchNearByDcs();
    setDeHaatCenters(dcs);
  }, []);
  useEffect(() => {
    getNearByDcs();
  }, [getNearByDcs]);
  return deHaatCenters.length > 0 ? <section className={`overflow-hidden ${className}`} data-testid="nearby-dc-list" data-sentry-component="NearByDCList" data-sentry-source-file="NearByDCList.tsx">
      <SubHeading variant={VARIANT.BIG} data-sentry-element="SubHeading" data-sentry-source-file="NearByDCList.tsx">{t('dehaat_store')}</SubHeading>
      <CarouselWithHandlers carouselClassName="mt-4" handlerClassName="mt-3 space-x-1 mx-4" pages={deHaatCenters.length} pageUpdateCallback={scrollDcsEvent} data-sentry-element="CarouselWithHandlers" data-sentry-source-file="NearByDCList.tsx">
        {deHaatCenters.map(({
        partner_id,
        ...deHaatCenter
      }) => <DCCard key={partner_id} {...deHaatCenter} containerClassName="w-full" />)}
      </CarouselWithHandlers>
    </section> : null;
};
export default NearByDCList;