import { COLORS } from '@dehaat/dds';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode } from 'react';
import { FeedTabs } from '@/constants/common';
import { DehaatHomeIcon, DehaatHomeIconFocussed } from './icons/DehaatHome';
import { DehaatShopIcon, DehaatShopIconFocussed } from './icons/DehaatShop';
import { FarmTalkIcon, FarmTalkIconFocussed } from './icons/FarmTalk';
interface BottomNavData {
  type: FeedTabs;
  name: string;
  icon: ReactNode;
  focussedIcon: ReactNode;
}
interface NavigationTabProps extends BottomNavData {
  isActive: boolean;
  handleTabClick: (type: FeedTabs) => void;
}
interface BottomNavProps {
  activeTab: FeedTabs;
  handleTabClick: (type: FeedTabs) => void;
}
const bottomNavData: BottomNavData[] = [{
  type: FeedTabs.HOME,
  name: 'dehaat',
  icon: <DehaatHomeIcon height={32} width={32} />,
  focussedIcon: <DehaatHomeIconFocussed height={32} width={32} />
}, {
  type: FeedTabs.COMMUNITY,
  name: 'farm_talk',
  icon: <FarmTalkIcon height={32} width={32} />,
  focussedIcon: <FarmTalkIconFocussed height={32} width={32} />
}, {
  type: FeedTabs.SHOP,
  name: 'dehaat_shop',
  icon: <DehaatShopIcon height={32} width={32} />,
  focussedIcon: <DehaatShopIconFocussed height={32} width={32} />
}];
const NavigationTab = ({
  name,
  icon,
  type,
  focussedIcon,
  isActive,
  handleTabClick
}: NavigationTabProps) => {
  const {
    t
  } = useTranslation('common');
  return <div onClick={() => handleTabClick(type)} className="flex-1 flex items-center flex-col" data-sentry-component="NavigationTab" data-sentry-source-file="BottomNavBar.tsx">
      {isActive ? focussedIcon : icon}
      <span style={{
      fontSize: '10px',
      color: isActive ? COLORS.primary100 : COLORS.neutral70
    }}>
        {t(name)}
      </span>
    </div>;
};
const BottomNavBar = ({
  activeTab,
  handleTabClick
}: BottomNavProps) => {
  return <section className="bg-white z-10 flex items-center py-2 border border-neutral-10 fixed bottom-0 left-0 right-0 w-webkit-fill" data-sentry-component="BottomNavBar" data-sentry-source-file="BottomNavBar.tsx">
      {bottomNavData.map(tab => <NavigationTab key={tab.name} {...tab} isActive={activeTab === tab.type} handleTabClick={handleTabClick} />)}
    </section>;
};
export default BottomNavBar;