import useAllPosts from '@dehaat/kisan-app-bl/hooks/useAllPostsV2';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { VENDOR_ID_COOKIE_NAME } from '@/constants/common';
import { POST_VIEW_TYPE } from '@/constants/postv2';
import { useAppSelector } from '@/hooks/reduxHooks';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import { selectLatLng } from '@/store/features/locationSlice';
import { getCookieValue } from '@/utils/helper';
import Caption, { VARIANT as CAPTION_VARIANT } from '../Caption';
import SimplePostList from './viewTypes/SimplePostList';
const PostManager = () => {
  const {
    t
  } = useTranslation('main');
  const {
    locale,
    asPath
  } = useRouter();
  const latLng = useAppSelector(selectLatLng);
  const [followToggled, setFollowToggled] = useState(false);
  const vendorId = getCookieValue(VENDOR_ID_COOKIE_NAME);
  const {
    fetchNext,
    posts,
    getPosts,
    loading,
    loadingNext,
    error
  } = useAllPosts(vendorId ? 'HYP' : 'NON_HYP', latLng || '', vendorId, locale);
  const {
    infiniteScrollRef
  } = useInfiniteScroll(fetchNext, {
    rootMargin: '0px 0px 300px 0px'
  });
  useEffect(() => {
    getPosts();
  }, [getPosts, followToggled]);
  return <section className="pb-8 bg-neutral-10" data-sentry-component="PostManager" data-sentry-source-file="PostManager.tsx">
      {posts.map((item, index) => {
      const {
        view_type
      } = item;
      switch ((view_type as POST_VIEW_TYPE)) {
        case POST_VIEW_TYPE.SIMPLE_POSTS:
          return <SimplePostList key={index} {...item} is_followed={item.head?.is_followed || false} callbackFn={() => setFollowToggled(prev => !prev)} />;
        default:
          return null;
      }
    })}

      {loading || loadingNext ? <div>
          <div className="animate-pulse bg-neutral-20 h-32 mt-4 rounded-lg mx-4" />
          <div className="animate-pulse bg-neutral-20 h-32 mt-4 rounded-lg mx-4" />
          <div className="animate-pulse bg-neutral-20 h-32 mt-4 rounded-lg mx-4" />
          <div className="animate-pulse bg-neutral-20 h-32 mt-4 rounded-lg mx-4" />
          <div className="animate-pulse bg-neutral-20 h-32 mt-4 rounded-lg mx-4" />
        </div> : null}
      {asPath !== '/?tab=1' && <div className={loading || loadingNext ? 'hidden' : undefined} ref={infiniteScrollRef} />}
      {error !== '' && <Caption variant={CAPTION_VARIANT.ONE} label={t(error)} className="text-center" />}
    </section>;
};
export default PostManager;