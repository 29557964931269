import Link from 'next/link';
import { Brand } from '@/models/Product';
import styles from '@/styles/TruncateContent.module.css';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import LazyImage from '../LazyImage';
import Paragraph, { VARIANT } from '../Paragraph';
interface Props {
  brand: Brand;
  className?: string;
}
const BrandImageName = ({
  brand: {
    name,
    imageUrl,
    slug
  },
  className = ''
}: Props) => {
  const slugEnc = encodeURIComponent(slug?.slug || '');
  const onBrandClick = () => {
    trackCustomEvent(CustomEventTypes.OPEN_PRODUCT_LIST, name);
  };
  return <Link href={`/products/brands/${slugEnc}`} className={`flex flex-col relative w-20 ${className}`} onClick={onBrandClick} data-sentry-element="Link" data-sentry-component="BrandImageName" data-sentry-source-file="BrandImageName.tsx">
      <div className="h-20 mb-2 w-20">
        <LazyImage alt={name} fallBackIcon={<div className="bg-neutral-300 h-20 flex items-center justify-center overflow-hidden rounded-full w-20">
              <span className="text-4xl">{name[0]}</span>
            </div>} imageUrl={imageUrl} className="object-cover h-20 w-20 rounded-full" data-sentry-element="LazyImage" data-sentry-source-file="BrandImageName.tsx" />
      </div>
      <Paragraph className={`text-center ${styles.truncate_after_two_lines}`} variant={VARIANT.MEDIUM} data-sentry-element="Paragraph" data-sentry-source-file="BrandImageName.tsx">
        {name}
      </Paragraph>
    </Link>;
};
export default BrandImageName;