import Image from 'next/image';
import { DeviceType } from '@/constants/common';
import { offerStripTypes } from '@/utils/api/getHomePageData';
interface OfferStripProps {
  imageUrls: offerStripTypes;
  altText?: string;
  deviceType: DeviceType;
}
const OfferStrip = ({
  imageUrls,
  altText = 'offer-banner',
  deviceType
}: OfferStripProps) => {
  const getImageForDevice = (deviceType: DeviceType, imageUrls: offerStripTypes) => {
    switch (deviceType) {
      case DeviceType.MOBILE:
        return imageUrls?.mobile_image || '';
      case DeviceType.TABLET:
        return imageUrls?.ipad_image || '';
      case DeviceType.DESKTOP:
        return imageUrls.desktop_image || '';
      default:
        return '';
    }
  };
  const bannerSrc = getImageForDevice(deviceType, imageUrls);
  return <div className="w-full " style={{
    margin: 0
  }} data-sentry-component="OfferStrip" data-sentry-source-file="offerStrip.tsx">
      <div className="relative w-full">
        <Image src={bannerSrc} alt={altText} layout="responsive" width={360} height={50} className="object-cover w-full" style={{
        maxHeight: '65px'
      }} data-sentry-element="Image" data-sentry-source-file="offerStrip.tsx" />
      </div>
    </div>;
};
export default OfferStrip;