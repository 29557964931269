import { useCallback, useEffect, useRef } from 'react'

export default function useThrottle<
  T extends string | number | boolean | object | Array<unknown>,
  K extends Function,
>(callback: K, limit: number) {
  const timer = useRef<number>()
  const wait = useRef<boolean>(false)

  useEffect(() => {
    return () => {
      if (timer.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timer.current)
      }
    }
  }, [])

  const throttledFunction = useCallback(
    (...params: T[]) => {
      if (!wait.current) {
        clearTimeout(timer.current)
        wait.current = true
        callback(...params)
        timer.current = window.setTimeout(() => {
          wait.current = false
        }, limit)
      }
    },
    [callback, limit],
  )

  return throttledFunction
}
